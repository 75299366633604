import type { PublicationCategory } from '~api'
import type { PublicationsNestedPath } from '~routes/paths'

export interface ListingState {
  pageSize: number
  sortBy: string
  sortDirection: string
  viewType: string
  mode: string
}

export interface PublicationsState {
  type: PublicationsNestedPath
}

type ListingNameState = {
  [key in ListingName]: ListingState
}

type PublicationCategoryState = {
  [key in PublicationCategory]: PublicationsState
}

export type SliceState = ListingNameState & PublicationCategoryState

export enum ViewType {
  cards = 'cards',
  table = 'table',
}

export enum ListingName {
  projects = 'projects',
  notebookPublications = 'notebookPublications',
  canvasPublications = 'canvasPublications',
  pasaPublications = 'pasaPublications',
  islePublications = 'islePublications',
  strategyPublications = 'strategyPublications',
  portfolios = 'portfolios',
  teams = 'teams',
}

export enum SearchParam {
  mode = 'mode',
  mode_filter = 'mode_filter[]',
  view = 'view',
  search = 'search',
  page = 'page',
  page_size = 'page_size',
  sort_by = 'sort_by',
  sort_direction = 'sort_direction',
  tags = 'tags[]',
  type = 'type',
  is_filtering = 'is_filtering',
}

export enum ViewMode {
  all = 'all',
  private = 'private',
  shared = 'shared',
  public = 'public',
}

export enum ViewModeFilter {
  private = 'private',
  public = 'public',
  shared_by_me = 'shared_by_me',
  shared_via_team = 'shared_via_team',
  shared_individually = 'shared_individually',
  my = 'my',
  others = 'others',
}

export enum TeamViewMode {
  all = 'all',
  my = 'my',
  community = 'community',
  shareable = 'shareable',
}

export enum SortingDirection {
  asc = 'asc',
  desc = 'desc',
}

export interface SortingDefinition<Field extends string> {
  field: Field
  direction: SortingDirection
}
